import React, { useState } from 'react'
import PropTypes from "prop-types"
import Tooltip from '@material-ui/core/Tooltip'
import infoIcon from '../../images/info.png'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import './index.css'

const tips = {
  "audio-spec": {
    heading: "Requirements related to audio submission",
    body: ["Apple Music accepts uncompressed PCM Wave files, with a sample rate of 44.1 Khz, and 16-bit or 24-bit resolution. For 24-bit files 96, 176.4, or 192 kHz is accepted.", "Note that if stereo audio source exists, it must be used.", "See Apple Digital Masters Source Profile for audio requirements specific to Apple Digital Masters content.", "Where stereo source is not available, as may be the case with certain vintage or field recordings, send audio source with two identical channels for left and right. Single channel audio will not be accepted.", "Important: All audio must be generated using a CODEC qualified and approved by Apple."]
  },
  "image-spec": {
    heading: "Requirements related to cover art submission",
    body: ["JPEG with .jpg extension (quality unconstrained) or PNG with .png extension", "Color space: RGB (screen standard)", "Album cover art files should be a recommended size of 3000 x 3000 pixels; minimum size of 1400 x 1400 pixels.", "Images must be square", "File formats: JPEG or PNG", "1:1 aspect ratio"]
  },
  "timeline-info": {
    heading: "Requirements and information related to submission timeline",
    body: ["When is it due: The sooner the better", "Upon submission to us: It will take approximately 3-5 days to turn it over to Apple", "Apple can take up to 2 weeks before going live on their platform", "After your submission: Our marketing team will reach out to you to deploy our Phase 2: Promotional efforts"]
  },
}

const Info = ({ title }) => {

  // local state

  const [open, setOpen] = useState(false)

  const { heading, body } = tips[title]

  // handlers

  const handleClick = () => setOpen(currentIsOpen => !currentIsOpen)

  // material ui styles

  const useStyles = makeStyles({
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      padding: 35,
      margin: 0,
      top: 10,
      width: 300
    },
    arrow: {
      color: 'white'
    }
  })

  const classes = useStyles()
  
  return(
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip 
        open={open}
        interactive
        arrow
        classes={classes}
        TransitionProps={{ timeout: {
          enter: 500,
          exit: 200
        } }}
        title={
          <div className="tooltip-content">
            <h2 className="py-2 bold">{heading}</h2>
            {body.map(curr => <p key={curr} className="py-2 italic">• {curr}</p>)}
          </div>
        }>  
        <img src={infoIcon} className="info-icon" onClick={handleClick} />
      </Tooltip>
    </ClickAwayListener>
  )
}

Info.propTypes = {
  title: PropTypes.string,
};

export default Info
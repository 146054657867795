import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Info from '../components/info'
import { description } from '../site-content'

import './index.css'

function IndexPage() {

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.cognitoforms.com/scripts/embed.js"
    script.addEventListener("load", () => window.Cognito.setCss('html { overflow: visible; }'))
    document.body.appendChild(script)
  })

  if(typeof window === 'undefined') return null
  return (
    <Layout>
      <SEO keywords={[`mix distribution`, `apple music`, `create/os`]} />
      <section className="text-center bg-black items-center flex flex-col">
      <h1 className="flex items-center text-white text-center no-underline text-3xl md:text-heading uppercase" style={{ fontFamily: 'km-bold'}}>            
          CREATE/OS: Mix Submission Form
        </h1>
        <h2 className="hidden sm:flex text-white text-center md:text-lg">Please fill out the following form to submit your DJ mix for consideration to Apple Music</h2>
        <p className="text-white my-16">
          {description}
        </p>        
        <div className="hidden sm:flex sm:flex-row items-center sm:justify-start sm:mt-8 w-full">
          <div className="flex items-center py-2">
            <Info title="audio-spec" />
            <span className="text-white pl-2 sm:pr-8">Audio Spec</span>
          </div>
          <div className="flex items-center py-2 sm:pr-8">
            <Info title="image-spec" />
            <span className="text-white pl-2">Image Spec</span>
          </div>
          <div className="flex items-center py-2 sm:pr-8">
            <Info title="timeline-info" />
            <span className="text-white pl-2">Timeline Info</span>
          </div>
        </div>        
        <iframe src="https://www.cognitoforms.com/f/Hq9U7v4GTE2hJ9L_tUceYQ?id=1" scrolling="no" seamless="seamless" height="946" width="100%" />
      </section>
    </Layout>
  );
}

export default IndexPage;
